import { defineStore } from "pinia";
import { v4 as uuidv4 } from "uuid";
import nuxtStorage from "nuxt-storage";

import type {
  IConfirmMessageResponse,
  IResendMessageResponse,
  ISendMessageResponse,
  IZaimStoreActions,
  IZaimStoreGetters,
  IZaimStoreState,
} from "~/store/onlineStore.types";

export const useZaimStore = defineStore<
  string,
  IZaimStoreState,
  IZaimStoreGetters,
  IZaimStoreActions
>("zaimStore", {
  state: () => ({
    show: false,
    disableButton: true,
    step: 1,
    sum: 15,
    resendTime: 0,
    interval: null,
    deviceId: uuidv4(),
    smsId: null,
    loanId: null,
    formData: {
      phone: "",
      birthdate: "",
      sopdn: false,
      sopdnAd: false,
    },
    loading: false,
    sendError: "",
    utm_source: "",
  }),
  getters: {
    getShow: (state) => state.show,
    getStep: (state) => state.step,
    getFormdata: (state) => state.formData,
    getResendTime: (state) => state.resendTime,
    getSendError: (state) => state.sendError,
    getDeviceId: (state) => state.deviceId,
    getSmsId: (state) => state.smsId,
    getLoanId: (state) => state.loanId,
    getLoading: (state) => state.loading,
  },
  actions: {
    setShow(showState: boolean) {
      this.show = showState;
    },
    setStep(stepState: number) {
      this.step = stepState;
    },
    setTimer(timer: number) {
      this.resendTime = timer;
      if (this.interval && this.resendTime > 0) {
        clearInterval(this.interval);
      }
      this.interval = setInterval(() => {
        this.resendTime = this.resendTime - 1;
        if (this.resendTime <= 0) {
          this.stopTimer();
        }
      }, 1000);
    },
    stopTimer() {
      if (this.interval) {
        this.sendError = "";
        clearInterval(this.interval);
      }
    },
    setPhone(value) {
      this.formData.phone = value;
    },
    setBirthdate(value) {
      this.formData.birthdate = value;
    },
    setSopdn(value) {
      this.formData.sopdn = value;
    },
    setSopdnad(value) {
      this.formData.sopdnAd = value;
    },
    setSendError(errorText) {
      this.sendError = errorText;
    },
    setSmsId(smsId) {
      this.smsId = smsId;
    },
    setLoanId(loanId) {
      this.loanId = loanId;
    },
    async sendMessage(query) {
      this.loading = true;
      this.sendError = "";
      if (!window.grecaptcha) {
        throw new Error("grecaptcha load error");
      }
      if (window.dataLayer) {
        window.dataLayer.push({
          event: "form_fio",
        });
      }
      // Счетчики Google и Yandex
      const counters: { [key: string]: any } = {};
      try {
        if (typeof window.Ya === "object") {
          const [counter] = window.Ya.Metrika2.counters();
          const yaCounters = window.Ya.Metrika2.counters().map((ct) => ct.id);
          if (typeof counter === "object") {
            counters.yaCounter = yaCounters;
            counters.yaClientId =
              window[`yaCounter${counter.id}`]!.getClientID();
          }
        }
        if (window.ga !== undefined) {
          if (window.ga.getAll().length) {
            counters.gaCounter =
              window.ga.getAll()[0].model.data.ea[":trackingId"];
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(`Counters error - ${error}`);
      }

      const recap = await window.grecaptcha.execute(
        useRuntimeConfig().public.captchaPublicKey,
        {
          action: "send_sms",
        },
      );
      if (!recap) {
        throw new Error("recaptchaExecute error");
      }

      const storageData = nuxtStorage.localStorage.getData("_l_uprid_store");

      let utmParams = {
        utm_source: query?.utm_source ?? "",
        utm_medium: query?.utm_medium ?? "",
        utm_content: query?.utm_content ?? "",
        utm_term: query?.utm_term ?? "",
        utm_campaign: query?.utm_campaign ?? "",
        wmid: query?.wmid?.toString() ?? "",
        aff_sub1: query?.aff_sub1?.toString() ?? "",
        aff_sub2: query?.aff_sub2?.toString() ?? "",
        url: window.location.href,
        referrer: document.referrer,
      };
      if (!query?.utm_source && storageData !== null) {
        utmParams = {
          utm_source: storageData?.utm_source ?? "",
          utm_medium: storageData?.utm_medium ?? "",
          utm_content: storageData?.utm_content ?? "",
          utm_term: storageData?.utm_term ?? "",
          utm_campaign: storageData?.utm_campaign ?? "",
          wmid: storageData?.wmid ?? "",
          aff_sub1: storageData?.aff_sub1 ?? "",
          aff_sub2: storageData?.aff_sub2 ?? "",
          url: window.location.href,
          referrer: document.referrer,
        };
      }

      // data для отправки запроса
      const sendData = {
        // GET запрос
        ...utmParams,
        // FormData с формы
        ...this.formData,
        // ID девайса
        ...{ deviceId: this.getDeviceId },
        // Счетчики
        ...counters,
        ...{ recap },
        sum: this.sum,
      };
      this.step = 2;
      // Запрос в NODE.
      try {
        const { data } = await useCsrfFetch<ISendMessageResponse>(
          `/get/api/login-lk`,
          {
            method: "post",
            body: sendData,
          },
        );
        // Если успешно
        if (data.value?.status) {
          data.value.id && this.setLoanId(data.value.id);
          this.setTimer(240);

          let storeData = "";
          const formData = JSON.parse(storageData);
          if (
            storageData !== null &&
            Object.keys(sendData).length > 0 &&
            query.utm_source &&
            query.utm_source.length > 0
          ) {
            const partnerParams = {
              ...utmParams,
              gaCounter: formData.gaCounter
                ? formData?.gaCounter.toString()
                : sendData?.gaCounter,
              yaCounter: formData?.yaCounter
                ? formData?.yaCounter
                : sendData?.yaCounter,
              yaClientId: formData?.yaClientId
                ? formData?.yaClientId.toString()
                : sendData?.yaClientId,
            };
            storeData = JSON.stringify({
              phone: this.formData.phone,
              birthdate: this.formData.birthdate,
              id: data.value.id,
              uuid: data.value.uuid,
              ...partnerParams,
            });
          } else {
            storeData = JSON.stringify({
              ...this.formData,
              ...utmParams,
              id: data.value?.id,
              gaCounter: formData?.gaCounter,
              yaCounter: formData?.yaCounter,
              yaClientId: formData?.yaClientId,
              uuid: data.value?.uuid,
            });
          }
          nuxtStorage.localStorage.setData(
            "_l_uprid_store",
            storeData,
            30,
            "d",
          );
        } else {
          this.step = 1;
          // Если ошибка
          data.value?.seconds && this.setTimer(data.value.seconds);
          if (data.value?.error) {
            this.sendError = data.value.error;
          }
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      this.loading = false;
    },
    async confirmMessage(code) {
      let result = true;
      if (!this.loading) {
        this.loading = true;
        const counters: { [key: string]: any } = {};
        try {
          if (typeof window.Ya === "object") {
            const [counter] = window.Ya.Metrika2.counters();
            const yaCounters = window.Ya.Metrika2.counters().map((ct) => ct.id);
            if (typeof counter === "object") {
              counters.yaCounter = yaCounters;
              counters.yaClientId =
                window[`yaCounter${counter.id}`].getClientID();
            }
          }
          if (window.ga !== undefined) {
            if (window.ga.getAll().length) {
              counters.gaCounter =
                window.ga.getAll()[0].model.data.ea[":trackingId"];
            }
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(`Counters error - ${error}`);
        }
        const storageData = nuxtStorage.localStorage.getData("_l_uprid_store");
        const formData = JSON.parse(storageData);
        const sendData = {
          ...formData,
          // Код подтверждения
          code,
          // uiidv4 девайса
          deviceId: this.getDeviceId,
          // ID формы
          loanId: this.getLoanId,
          // Счетчики
          ...counters,
        };
        try {
          const { data } = await useCsrfFetch<IConfirmMessageResponse>(
            `/get/api/confirm-code`,
            {
              method: "post",
              body: sendData,
            },
          );
          window.dataLayer.push({
            event: "generate_lead_sdo",
          });
          if (data.value?.status) {
            if ("ym" in window) {
              try {
                ym(86707384, "reachGoal", "get_button_confirm_sms");
              } catch (e) {}
            }

            this.disableButton = false;
            setTimeout(() => {
              if (data.value.scLkUrl) {
                window.location.href = data.value.scLkUrl;
              } else {
                window.location.href = `${data.value.uri}/landing/login?token=${data.value.token}&fio=&deviceId=${this.getDeviceId}`;
              }
            }, 300);
          } else {
            data.value?.seconds && this.setTimer(data.value.seconds);
            data.value?.error && this.setSendError(data.value.error);
          }
        } catch (e) {
          result = false;
        }
      }
      this.loading = false;
      return result;
    },

    /**
     * Отправка повторной SMS
     */
    async resendMessage() {
      const recap = await window.grecaptcha.execute(
        useRuntimeConfig().public.captchaPublicKey,
        {
          action: "send_sms",
        },
      );
      if (!recap) {
        throw new Error("recaptchaExecute error");
      }
      const sendData = {
        uuid: this.getDeviceId,
        ...{ recap },
      };

      const { data } = await useCsrfFetch<IResendMessageResponse>(
        `/get/api/resend-code`,
        {
          method: "post",
          body: sendData,
        },
      );
      if (data.value?.status) {
        this.setTimer(240);
      } else {
        data.value?.seconds && this.setTimer(data.value.seconds);
        data.value?.error && this.setSendError(data.value.error);
      }
    },
  },
});
